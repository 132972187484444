import React, {useContext} from "../../_snowpack/pkg/react.js";
import {BrowserRouter as Router, Redirect, Route} from "../../_snowpack/pkg/react-router-dom.js";
import UserContext from "../contexts/user.js";
import MainCMS from "./MainCMS.js";
import MainLogin from "./MainLogin.js";
export default function AppRouter() {
  const [user] = useContext(UserContext) ?? [];
  const isLoggedIn = () => user && user._id;
  return /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(Route, {
    path: "/"
  }, isLoggedIn() ? /* @__PURE__ */ React.createElement(Redirect, {
    to: "/editor"
  }) : /* @__PURE__ */ React.createElement(Redirect, {
    to: "/login"
  })), /* @__PURE__ */ React.createElement(Route, {
    path: "/login"
  }, /* @__PURE__ */ React.createElement(MainLogin, null)), /* @__PURE__ */ React.createElement(Route, {
    path: "/editor"
  }, /* @__PURE__ */ React.createElement(MainCMS, null)));
}
