import React, {useContext} from "../../_snowpack/pkg/react.js";
import UserContext from "../contexts/user.js";
import LogoutButton from "./LogoutButton.js";
function Header({}) {
  const [user] = useContext(UserContext) ?? [];
  return /* @__PURE__ */ React.createElement("div", {
    className: "Header content-bar"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "btn-grp-left"
  }, /* @__PURE__ */ React.createElement("button", {
    className: "Button"
  }, "Home")), /* @__PURE__ */ React.createElement("div", {
    className: "btn-grp-right"
  }, user && /* @__PURE__ */ React.createElement(LogoutButton, null), /* @__PURE__ */ React.createElement("button", {
    className: "Button"
  }, "Reader"), /* @__PURE__ */ React.createElement("button", {
    className: "Button dark"
  }, "Github")));
}
export default Header;
