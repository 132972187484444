import React, {useContext, useEffect, useState} from "../../_snowpack/pkg/react.js";
import UserContext from "../contexts/user.js";
import useFetch from "../hooks/useFetch.js";
import "../styles/MainLogin.css";
import {getLoginEndpoint} from "../utils/routeGetters.js";
export default function MainLogin() {
  const [, setUser] = useContext(UserContext) ?? [];
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const {callFetch, body} = useFetch(getLoginEndpoint(), {
    credentials: "include"
  });
  useEffect(() => {
    async function handleUserLogin() {
      if (!body || !body.content) {
        console.log("no body.user...");
        return;
      }
      setUser ? setUser(body.content) : console.error("UserContext has not defined setUser function!");
    }
    handleUserLogin();
  }, [body]);
  function handleFormSubmit(e) {
    e.preventDefault();
    callFetch("POST", {username, password});
  }
  return /* @__PURE__ */ React.createElement("div", {
    className: "login-container"
  }, /* @__PURE__ */ React.createElement("form", {
    className: "form login-form",
    onSubmit: handleFormSubmit
  }, /* @__PURE__ */ React.createElement("label", null, /* @__PURE__ */ React.createElement("div", null, "Username:"), /* @__PURE__ */ React.createElement("input", {
    type: "text",
    id: "username",
    value: username,
    onChange: (e) => setUsername(e.target.value),
    required: true
  })), /* @__PURE__ */ React.createElement("label", null, /* @__PURE__ */ React.createElement("div", null, "Password:"), /* @__PURE__ */ React.createElement("input", {
    type: "password",
    id: "password",
    value: password,
    onChange: (e) => setPassword(e.target.value),
    required: true
  })), /* @__PURE__ */ React.createElement("button", {
    className: "Button",
    type: "submit"
  }, "Login")));
}
