import React, {useReducer} from "../../_snowpack/pkg/react.js";
import {postReducer, PostsContext} from "../contexts/Posts.js";
import UserContext from "../contexts/user.js";
import useStickyState from "../hooks/useStickyState.js";
export default function GlobalContextStore({
  children
}) {
  const [user, setUser] = useStickyState(null, "USER_INFO");
  const [posts, dispatch] = useReducer(postReducer, []);
  return /* @__PURE__ */ React.createElement(UserContext.Provider, {
    value: [user, setUser]
  }, /* @__PURE__ */ React.createElement(PostsContext.Provider, {
    value: {posts, dispatch}
  }, children));
}
