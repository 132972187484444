import {useContext, useEffect} from "../../_snowpack/pkg/react.js";
import UserContext from "../contexts/user.js";
import useFetch from "./useFetch.js";
export default function useAuthFetch(url, options = {}) {
  const optionsWithAuth = {credentials: "include", ...options};
  const {callFetch, isLoading, isError, response, body} = useFetch(url, optionsWithAuth);
  const [, setUser] = useContext(UserContext) ?? [];
  useEffect(() => {
    if (isUnauthenticatedResponse(body)) {
      if (setUser) {
        console.log("Logging user out automatically...");
        setUser(null);
      } else {
        console.error("Could not setUser because UserContext was not defined");
      }
    }
  }, [body]);
  return {callFetch, isLoading, isError, response, body};
}
function isUnauthenticatedResponse(responseBody) {
  return typeof responseBody === "string" && responseBody === "Unauthorized";
}
