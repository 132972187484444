import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

undefined /* [snowpack] import.meta.hot */ ;
export const {SNOWPACK_PUBLIC_API_URL} = __SNOWPACK_ENV__;
export const getLoginEndpoint = () => `${SNOWPACK_PUBLIC_API_URL}/api/auth/login`;
export function getUserPostsEndpoint(user) {
  return user ? `${SNOWPACK_PUBLIC_API_URL}/api/users/${user?._id}/blogs-all` : "";
}
export function getPostBlogAPIEndpoint(userId) {
  return `${SNOWPACK_PUBLIC_API_URL}/api/users/${userId}/blogs`;
}
export function getUpdateBlogAPIEndpoint(postId) {
  return `${SNOWPACK_PUBLIC_API_URL}/api/blogs/${postId}`;
}
