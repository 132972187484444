import React from "../../_snowpack/pkg/react.js";
function addPosts(posts, newPosts) {
  return [...posts, ...newPosts];
}
function setPosts(newPosts) {
  return [...newPosts];
}
function updatePost(posts, id, updatedPost) {
  return posts.map((post) => post._id === id ? updatedPost : post);
}
function deletePost(posts, id) {
  return posts.filter((post) => post._id !== id);
}
export const postReducer = (state, action) => {
  switch (action.type) {
    case "add":
      return addPosts(state, action.posts);
    case "set":
      return setPosts(action.posts);
    case "update":
      return updatePost(state, action.id, action.post);
    case "delete":
      return deletePost(state, action.id);
    default:
      throw new Error(`Malformed action--unhandled action type ${action.type}`);
  }
};
export const PostsContext = React.createContext(null);
