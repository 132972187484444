import {useEffect, useState} from "../../_snowpack/pkg/react.js";
const useFetch = function(url, options = {}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [response, setResponse] = useState(null);
  const [resBody, setResBody] = useState(null);
  const [reqInfo, setReqInfo] = useState(null);
  const abortController = new AbortController();
  useEffect(() => {
    if (reqInfo === null) {
      return;
    }
    console.log("calling useEffect fetch...");
    let isMounted = true;
    const fullOptions = getFetchOptions(options, reqInfo, abortController);
    const doFetch = async () => {
      console.log("fetching");
      const res = await fetch(url, fullOptions);
      if (!isMounted)
        return;
      if (!res.ok) {
        setIsError(true);
      }
      let responseBody = await parseResponse(res);
      if (!abortController.signal.aborted && isMounted) {
        setResBody(responseBody);
        setResponse(res);
        setIsLoading(false);
      }
    };
    doFetch();
    return () => {
      console.log("Abandoning fetch request");
      isMounted = false;
      abortController?.abort();
    };
  }, [reqInfo]);
  function callFetch(method, body) {
    setReqInfo({method, body});
  }
  return {callFetch, isLoading, isError, response, body: resBody};
};
async function parseResponse(res) {
  const responseText = await res.text();
  try {
    return JSON.parse(responseText);
  } catch (err) {
    console.log(`Could not parse fetch response ${responseText} as JSON`);
    return responseText;
  }
}
function getFetchOptions(options, reqInfo, abortController) {
  const headers = {
    "Access-Control-Allow-Methods": reqInfo.method,
    "Content-Type": "application/json",
    ...options.headers
  };
  let body = reqInfo.body;
  try {
    body = JSON.stringify(body);
  } catch (_) {
    console.log("Request body not serializable as JSON", body);
  }
  return {
    ...options,
    signal: abortController.signal,
    method: reqInfo.method,
    headers,
    body
  };
}
export default useFetch;
