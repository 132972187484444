import React, {useEffect} from "../../_snowpack/pkg/react.js";
import {Route, Switch, useRouteMatch} from "../../_snowpack/pkg/react-router-dom.js";
import {PostsContext} from "../contexts/Posts.js";
import UserContext from "../contexts/user.js";
import useAuthFetch from "../hooks/useAuthFetch.js";
import useDefinedContext from "../hooks/useDefinedContext.js";
import {getUserPostsEndpoint} from "../utils/routeGetters.js";
import Editor from "./Editor.js";
import Sidebar from "./Sidebar.js";
export default function MainCMS() {
  const {path} = useRouteMatch();
  const [user] = useDefinedContext(UserContext);
  const {dispatch} = useDefinedContext(PostsContext);
  const {body, callFetch} = useAuthFetch(getUserPostsEndpoint(user));
  useEffect(() => {
    callFetch("GET");
  }, []);
  useEffect(() => {
    if (typeof body === "string") {
      return console.error("unauthorized response received", body);
    }
    const content = body?.content ?? [];
    if (!Array.isArray(content)) {
      return console.error("Could not set Posts state because response body was not Array", body);
    }
    dispatch({type: "set", posts: content});
  }, [body]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Sidebar, null), /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: `${path}`
  }, /* @__PURE__ */ React.createElement("div", null, "Select a post or add a new one!")), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: `${path}/:postid`
  }, /* @__PURE__ */ React.createElement(Editor, null))));
}
