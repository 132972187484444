import React from "../../_snowpack/pkg/react.js";
import {Link, useRouteMatch} from "../../_snowpack/pkg/react-router-dom.js";
import formatDate from "../utils/formatDate.js";
export default function SidebarPostItem({post}) {
  const {url} = useRouteMatch();
  return /* @__PURE__ */ React.createElement(Link, {
    to: `${url}/${post._id}`,
    className: "Link"
  }, /* @__PURE__ */ React.createElement("p", null, post.title), /* @__PURE__ */ React.createElement("p", null, formatDate(new Date(post.publishDate))));
}
