import React, {useState} from "../_snowpack/pkg/react.js";
import Footer from "./components/Footer.js";
import GlobalContextStore from "./components/GlobalContextStore.js";
import Header from "./components/Header.js";
import AppRouter from "./components/AppRouter.js";
import "./styles/App.css";
function App({}) {
  const [theme, setTheme] = useState("theme-dark");
  return /* @__PURE__ */ React.createElement(GlobalContextStore, null, /* @__PURE__ */ React.createElement("div", {
    className: `App ${theme}`
  }, /* @__PURE__ */ React.createElement(Header, null), /* @__PURE__ */ React.createElement("main", {
    className: "Main"
  }, /* @__PURE__ */ React.createElement(AppRouter, null)), /* @__PURE__ */ React.createElement(Footer, null)));
}
export default App;
