import React, {useEffect, useState} from "../../_snowpack/pkg/react.js";
import {useParams} from "../../_snowpack/pkg/react-router.js";
import {PostsContext} from "../contexts/Posts.js";
import UserContext from "../contexts/user.js";
import useAuthFetch from "../hooks/useAuthFetch.js";
import useDefinedContext from "../hooks/useDefinedContext.js";
import "../styles/Editor.css";
import {
  getPostBlogAPIEndpoint,
  getUpdateBlogAPIEndpoint
} from "../utils/routeGetters.js";
const defaultPost = {
  title: "New Post",
  content: "Hello world"
};
export default function Editor() {
  const [user] = useDefinedContext(UserContext);
  const {postid} = useParams();
  const {posts, dispatch} = useDefinedContext(PostsContext);
  const activePost = posts.find((post) => post._id === postid);
  const [editedPost, setEditedPost] = useState({...defaultPost});
  function handlePostUpdate(e) {
    const [name, value] = [e.currentTarget.name, e.currentTarget.value];
    setEditedPost((prevPost) => ({
      ...prevPost,
      [name]: value
    }));
  }
  const ApiPostBlogEndpoint = isNewPost() ? getPostBlogAPIEndpoint(user?._id ?? "undefined") : getUpdateBlogAPIEndpoint(postid);
  const {callFetch, body: publishRequestResponse} = useAuthFetch(ApiPostBlogEndpoint);
  function publishPost(e) {
    e.preventDefault();
    const fetchBody = {
      title: editedPost.title,
      content: editedPost.content,
      publishDate: activePost?.publishDate ?? new Date()
    };
    callFetch(isNewPost() ? "POST" : "PUT", fetchBody);
  }
  function isNewPost() {
    return postid === "new";
  }
  useEffect(() => {
    if (activePost?.title && activePost?.content) {
      setEditedPost({title: activePost.title, content: activePost.content});
    } else {
      setEditedPost({...defaultPost});
    }
  }, [postid]);
  useEffect(() => {
    if (!publishRequestResponse) {
      return;
    }
    const responsePost = publishRequestResponse.content;
    if (!responsePost._id || !responsePost.title) {
      return;
    }
    if (isNewPost()) {
      dispatch({type: "add", posts: [responsePost]});
    } else {
      dispatch({type: "update", id: postid, post: responsePost});
    }
  }, [publishRequestResponse]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "EditorContainer"
  }, /* @__PURE__ */ React.createElement("form", {
    className: "EditorColumn",
    onSubmit: publishPost
  }, /* @__PURE__ */ React.createElement("div", {
    className: "EditorToolbar"
  }, "Tools n such"), /* @__PURE__ */ React.createElement("div", {
    className: "EditorPage"
  }, /* @__PURE__ */ React.createElement("input", {
    className: "EditorPage-Title",
    value: editedPost.title,
    onChange: handlePostUpdate,
    name: "title"
  }), /* @__PURE__ */ React.createElement("textarea", {
    className: "EditorPage-Content",
    value: editedPost.content,
    onChange: handlePostUpdate,
    name: "content"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "EditorPublishBar"
  }, /* @__PURE__ */ React.createElement("button", {
    className: "Button",
    type: "button"
  }, "Save as draft"), /* @__PURE__ */ React.createElement("button", {
    className: "Button",
    type: "button"
  }, "Schedule publication"), /* @__PURE__ */ React.createElement("button", {
    className: "Button",
    type: "submit"
  }, "Publish"))));
}
