import React, {useContext} from "../../_snowpack/pkg/react.js";
import UserContext from "../contexts/user.js";
export default function LogoutButton() {
  const [, setUser] = useContext(UserContext) ?? [];
  function onClick() {
    if (setUser) {
      setUser(null);
    } else {
      console.error("Can't logout because setUser was not defined!");
    }
  }
  return /* @__PURE__ */ React.createElement("button", {
    onClick,
    className: "Button"
  }, "Logout");
}
