import React from "../../_snowpack/pkg/react.js";
import {useRouteMatch, Link} from "../../_snowpack/pkg/react-router-dom.js";
import {PostsContext} from "../contexts/Posts.js";
import useDefinedContext from "../hooks/useDefinedContext.js";
import "../styles/Sidebar.css";
import SidebarPostItem from "./SidebarPostItem.js";
export default function Sidebar() {
  const {posts} = useDefinedContext(PostsContext);
  const {url} = useRouteMatch();
  const [publishedPosts, drafts] = posts ? splitPostsByPublicationStatus(posts) : [[], []];
  return /* @__PURE__ */ React.createElement("div", {
    className: "Sidebar"
  }, /* @__PURE__ */ React.createElement(Link, {
    to: `${url}/new`
  }, /* @__PURE__ */ React.createElement("button", {
    className: "Button"
  }, "New Post")), /* @__PURE__ */ React.createElement("div", {
    className: "SidebarSectionheader"
  }, "Drafts"), /* @__PURE__ */ React.createElement("ul", null, drafts.map((post) => /* @__PURE__ */ React.createElement("li", {
    key: post._id,
    style: {borderBottom: "1px solid grey"}
  }, /* @__PURE__ */ React.createElement(SidebarPostItem, {
    post
  })))), /* @__PURE__ */ React.createElement("div", {
    className: "SidebarSectionheader"
  }, "Published"), /* @__PURE__ */ React.createElement("ul", null, publishedPosts.map((post) => /* @__PURE__ */ React.createElement("li", {
    key: post._id,
    style: {borderBottom: "1px solid grey"}
  }, /* @__PURE__ */ React.createElement(SidebarPostItem, {
    post
  })))));
}
function splitPostsByPublicationStatus(posts) {
  const postReducer = (acc, post) => {
    const [publishedPosts, drafts] = acc;
    return isPublished(post) ? [[...publishedPosts, post], drafts] : [publishedPosts, [...drafts, post]];
  };
  return posts.reduce(postReducer, [[], []]);
}
function isPublished(post) {
  return new Date(post.publishDate) <= new Date();
}
